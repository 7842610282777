<template>
  <div>
    <p class="is-size-5 my-2">
      {{ log.first_name }} {{ log.last_name }} {{ log.message }}
    </p>
    <p class="is-size-6 my-1">
      <strong>Author's email:</strong> {{ log.email }}
    </p>
    <p class="is-size-6 my-1">
      <strong>Date:</strong> {{ log.created_at }}
    </p>
    <p class="is-size-6 my-1">
      <strong>Resource:</strong> {{ log.resource }}
    </p>
    <p class="is-size-6 my-1">
      Detailed difference in resource's state after the change:
    </p>
    <code-diff
      :old-string="log.old_state"
      :new-string="log.new_state"
      :context="10"
      :output-format="'side-by-side'"
    />
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api';
import CodeDiff from 'vue-code-diff';
import { deepParseJson } from 'deep-parse-json';
import { fetchLogByIdService } from '@/services/audit-log-service/auditLogRequests';
import { convertUTCToLocalTime } from '@/helpers/util';


export default {
    components: {
        CodeDiff
    },
    setup () {
        const log = ref({});
        const fetchLog = async () => {
            try {
                const { data: { data } } = await fetchLogByIdService(this.$route.params.id);
                let old_state = '';
                let new_state = '';

                if (data.new_state) {
                    new_state = JSON.stringify(JSON.parse(data.new_state), null, '\t');
                    if (old_state.data) {
                        new_state.data = JSON.stringify(JSON.parse(new_state.data), null, '\t');
                    }
                }

                log.value = {
                    id: data.id,
                    resource: data.resource,
                    old_state: JSON.stringify(deepParseJson(JSON.stringify(JSON.parse(data.old_state))), null, '\t'),
                    new_state: JSON.stringify(deepParseJson(JSON.stringify(JSON.parse(data.new_state))), null, '\t'),
                    message: data.message,
                    created_at: convertUTCToLocalTime(data.created_at),
                    first_name: data.first_name,
                    last_name: data.last_name,
                    email: data.email
                };
            } catch(err) {
                console.error(err);
            }
        };
        onMounted(() => {
            fetchLog();
        });

        return {
            log
        };
    }
};
</script>

<style>


.has-light-bg {
  background: #ededed !important;
}

.has-insert-shadow {
  -webkit-box-shadow: -10px 9px 15px -6px rgba(0, 0, 0, 0.1);
  box-shadow: -10px 9px 15px -6px rgba(0, 0, 0, 0.1);
}

.d2h-info {
  display: none
}
</style>